import Component from '../../libs/component';
import { register } from '../../libs/register';

class History extends Component {
    
    constructor(container) {
        super('widget-history');

        this.history = container;
        this.filtersBar = container.querySelector(this._el('filters-bar',true));
        this.filterBtn = this.filtersBar.querySelectorAll(this._el('btn-tab',true));
        this.historyWrapper = container.querySelector('.widget-history__step.step-third .widget-history__wrapper');
        this.items = this.historyWrapper.querySelectorAll('.elem-history-card');
        
        this.FILTERS_OFFSET = this.filtersBar.offsetTop;

        this.loadData = true;

        this._init();
    }

    _init() {

        this.filterBtn.forEach((el) => {

                el.addEventListener('click', (ev) => {
                    
                    if(!el.matches('.selected')) {
                       
                        el.parentElement
                            .querySelectorAll('button')
                            .forEach((button) => {
                                button.classList.remove('selected');
                                
                                this._hideHistory();
                            });

                        el.classList.add('selected');
                        
                        this._showHistory(ev);

                    } else {
                        el.classList.remove('selected');

                        this._hideHistory();
                    }
                    
                });
            });


        this._addScrollListener();
    }

    

    _showHistory(el) {
        let _button = el.currentTarget;
        let _tabTarget = _button.dataset.target;

        this.historyLine = this.history.querySelectorAll('.elem-history-card__line');
        this.historyEvents = this.history.querySelectorAll('.elem-history-card__event');
        
        this.historyLine.forEach((el) => {

            if (el.dataset.history != _tabTarget && el.dataset.history != 'all') {
                el.classList.add('hidden');
            }
        });

        this.historyEvents.forEach((el) => {

            if (el.dataset.history != _tabTarget && el.dataset.history != 'all') {
                el.classList.add('hidden');
            }
        });
    }

    _hideHistory() {

        this.historyLine = this.history.querySelectorAll('.elem-history-card__line');
        this.historyEvents = this.history.querySelectorAll('.elem-history-card__event');

        this.historyLine.forEach((el) => {
            el.classList.remove('hidden');
        });

        this.historyEvents.forEach((el) => {
            el.classList.remove('hidden');
        });
        
    }
    
    throttle(callback, limit) {
        let wait = false;                  // Initially, we're not waiting
        return function () {               // We return a throttled function
            if (!wait) {                   // If we're not waiting
                callback.call();           // Execute users function
                wait = true;               // Prevent future invocations
                setTimeout(function () {   // After a period of time
                    wait = false;          // And allow future invocations
                }, limit);
            }
        }
    }

    _addScrollListener() {
        let sticky = false,
            prevScrollPos = 0,
            lastScrollPos = window.scrollY || window.pageYOffset;

        this._checkScrollPosition(lastScrollPos,prevScrollPos,this.FILTERS_OFFSET);
        

        document.addEventListener('scroll', () => {
            lastScrollPos = window.pageYOffset;
            
            if (!sticky) {
                window.requestAnimationFrame( () => {
                    this._checkScrollPosition(lastScrollPos,prevScrollPos,this.FILTERS_OFFSET);
                    prevScrollPos = lastScrollPos;
                    sticky = false;
                });

                sticky = true;
            }
        });

        
        window.addEventListener("scroll", this.throttle( () => {
            if (this.loadData) {
                this._infiniteScroll();
            } else {
                this._infiniteScroll(false);
            }
                
            
        }, 300));
    }

    _checkScrollPosition(lastPos,prevPos,offset) {
        
        if (lastPos > offset) {
            this.filtersBar.classList.add('sticky');

            if (lastPos - prevPos > 0) {
                this.filtersBar.classList.add('sticky-scrolled');
            } else {
                this.filtersBar.classList.remove('sticky-scrolled');
            }
        } else {
            this.filtersBar.classList.remove('sticky');
        }
    }

    _infiniteScroll(load=true) {
        let pixelsFromWindowBottomToBottom = 0 + document.documentElement.scrollHeight - window.pageYOffset - window.innerHeight;
        let startScroll;

        if (window.deviceBreakpoints.bpDesktop.matches === true) {
            startScroll = 400;
        } else {
            startScroll = 800;
        }

        if (load) {

            if (pixelsFromWindowBottomToBottom < startScroll){
                
                this._fetchTimelineItems();
                //console.debug(pixelsFromWindowBottomToBottom);
            }
        }

    }



    _fetchTimelineItems() {

        let request = new XMLHttpRequest();
        let data;
        let dataObj;
        let dataUrl = this.historyWrapper.dataset.url;

        let resPath = this.endpoint ? this.endpoint : dataUrl;

        let timelinePointer = this.historyWrapper.querySelectorAll('.elem-history-card').length || 0;
        let target;

        this.filterSelected = this.filtersBar.querySelectorAll('.widget-history__btn-tab.selected');

        // get data target from selected button
        if (this.filterSelected.length > 0) {

            this.filterSelected.forEach((el) => {
                
                target = el.dataset.target;
            });
        }

        resPath += `?timelinePointer=${timelinePointer}`;

        request.open('GET', resPath, true );
        
        request.onload = () => {
            
            if (request.status === 200) {
                data = request.responseText;
                dataObj = JSON.parse(data);

                dataObj.timelines.forEach((timeline) => {
                    
                    let timelineItem = document.createElement('div');
                    timelineItem.classList.add('elem-history-card');
                    
                    let timelineContainer = document.createElement('div');
                    timelineContainer.classList.add('elem-history-card__container');

                    let timelineContent = document.createElement('div');
                    timelineContent.classList.add('elem-history-card__content');


                    // Year Column
                    let yearCol = document.createElement('div');
                    yearCol.classList.add('elem-history-card__year-col');

                    let mainYear = document.createElement('p');
                    mainYear.appendChild(document.createTextNode( timeline.year));
                    mainYear.classList.add('elem-history-card__year');

                    yearCol.appendChild(mainYear)

                    // Line Column
                    let lineCol = document.createElement('div');
                    lineCol.classList.add('elem-history-card__line-col');

                    let lineNets = document.createElement('div');
                    lineNets.classList.add('elem-history-card__line');
                    lineNets.classList.add('nets');
                    lineNets.dataset.history = 'nets';

                    let lineNexi = document.createElement('div');
                    lineNexi.classList.add('elem-history-card__line');
                    lineNexi.classList.add('nexi');
                    lineNexi.dataset.history = 'nexi';

                    let lineSia = document.createElement('div');
                    lineSia.classList.add('elem-history-card__line');
                    lineSia.classList.add('sia');
                    lineSia.dataset.history = 'sia';

                    let dotNexi = document.createElement('div');
                    dotNexi.classList.add('elem-history-card__dot');

                    let dotNets = document.createElement('div');
                    dotNets.classList.add('elem-history-card__dot');

                    let dotSia = document.createElement('div');
                    dotSia.classList.add('elem-history-card__dot');

                    // Event Column
                    let eventsCol = document.createElement('div');
                    eventsCol.classList.add('elem-history-card__events-col');

                    lineCol.appendChild(lineNets);
                    lineCol.appendChild(lineNexi);
                    lineCol.appendChild(lineSia);

                    if (timeline.nexi && timeline.nets && timeline.sia) {
                        lineNexi.appendChild(dotNexi);
                        lineNets.appendChild(dotNets);
                        lineSia.appendChild(dotSia);
                    } else if (timeline.nexi && timeline.nets) {
                        lineNexi.appendChild(dotNexi);
                        lineNets.appendChild(dotNets);
                    } else if (timeline.nexi && timeline.sia) {
                        lineNexi.appendChild(dotNexi);
                        lineSia.appendChild(dotSia);
                    } else if (timeline.nets && timeline.sia) {
                        lineNexi.appendChild(dotNets);
                        lineSia.appendChild(dotSia);
                    } else if (timeline.nexi) {
                        lineNexi.appendChild(dotNexi);
                    } else if (timeline.nets) {
                        lineNets.appendChild(dotNets);
                    } else if (timeline.sia) {
                        lineSia.appendChild(dotSia);
                    }

                    if(timeline.nexiColor != "") {
                        dotNexi.style.backgroundColor = timeline.nexiColor;
                    }

                    if(timeline.netsColor != "") {
                        dotNets.style.backgroundColor = timeline.netsColor;
                    }

                    if(timeline.siaColor != "") {
                        dotSia.style.backgroundColor = timeline.siaColor;
                    }

                    timeline.events.forEach((event) => {
                        let eventItem = document.createElement('div');
                        eventItem.classList.add('elem-history-card__event');
                        eventItem.dataset.history = event.brand;

                        let eventInfo = document.createElement('div');
                        eventInfo.classList.add('elem-history-card__event-info');

                        
                        let eventDescription = document.createElement('div');
                        eventDescription.classList.add('elem-history-card__event-description');
                    
                        let text = event.description.replace('/r/n', '');
                        eventDescription.innerHTML = text;

                        if(event.logoPath) {
                            let eventLogo = document.createElement('div');
                            eventLogo.classList.add('elem-history-card__event-logo');
                            
                            let logo = document.createElement('img');
                            logo.src = event.logoPath;
                            logo.alt = 'Logo';

                            eventLogo.appendChild(logo);
                            eventInfo.appendChild(eventLogo);
                        }

                        if ( event.logoLabel ) {
                            let eventYear = document.createElement('div');
                             eventYear.classList.add('elem-history-card__event-year');
                            let year = event.logoLabel.replace('/r/n', '');
                            eventYear.innerHTML = year;

                            eventInfo.appendChild(eventYear);
                        }
                        
                       
                        
                        eventItem.appendChild(eventInfo);
                        eventItem.appendChild(eventDescription);

                        eventsCol.appendChild(eventItem);
                    });

                    timelineItem.appendChild(timelineContainer);
                    timelineContainer.appendChild(timelineContent);
                    
                    timelineContent.appendChild(yearCol);
                    timelineContent.appendChild(lineCol);
                    timelineContent.appendChild(eventsCol);

                    // if a filter button is selected, show related items
                    if(target) {
                        timelineItem.querySelectorAll('.elem-history-card__line').forEach((el) => {
                            if (el.dataset.history != target ) {
                                el.classList.add('hidden');
                            }
                        });

                        timelineItem.querySelectorAll('.elem-history-card__event').forEach((el) => {
                            if (el.dataset.history != target ) {
                                el.classList.add('hidden');
                            }
                        });
                        
                    }

                    this.historyWrapper
                        .appendChild(timelineItem);

                });

                // If 'itemLeft' is 'false' set 'this.loadData' var to false, to stop load items through infinite scroll
                if (!dataObj.itemsLeft) {
                    this.loadData = false;
                }

            } else {
                console.log("We reached our target server, but it returned an error");
            }
        
        }

        
        request.onerror = () => {
            console.warn('Timeline load scroll network ERROR');
        };
        
        request.send();
        
    }

}


register.registerClass('.widget-history', History);