import Component from '../../libs/component';
import { register } from '../../libs/register';

const tabId = window.location.href.split('tabId=').pop().split('&')[0];

class TabsSlide extends Component {
    constructor(container) {
        super('widget-tab-slide');

        this.tabContainer = container;
        this.tabGroup = this.tabContainer.querySelector('.widget-tab-slide__tab-group');
        this.tabItems = this.tabGroup.querySelectorAll('.widget-tab-slide__btn');
        this.tabContent = this.tabContainer.querySelector('.widget-tab-slide__tab-content');
        this.tabPane = this.tabContent.querySelectorAll('.widget-tab-slide__tab-pane');

        this.tabButtonPaneSelected = document.getElementById(tabId);
        this.tabButtonSelected = document.querySelector('[data-target="#' + tabId + '"]');

        this._setTab();

        this.tabItems.forEach((el) => {
            el.addEventListener('click', (ev) => {
                this._openTab(ev);
            });
        });

        document.addEventListener('bpMobileTablet', () => this._addMobileInit());
        document.addEventListener('bpDesktop', () => this._destroySwiper());

        window.addEventListener('load', () => this. _scrollToTab() );
        window.addEventListener('visibilitychange', () => this. _scrollToTab() );

        if (window.deviceBreakpoints.bpMobileTablet.matches === true) this._addMobileInit();
    }

    _scrollToTab() {
        if (this.tabButtonSelected) {
            this.tabButtonSelected.scrollIntoView();
        }
    }

    _removeActiveTabs() {
        this.tabPane.forEach((el) => {
            el.classList.remove('active');
        });

        this.tabItems.forEach((el) => {
            el.classList.remove('active');
        });
    }

    _setTab() {
        if (this.tabItems) {
            this._removeActiveTabs();

            if (this.tabButtonSelected) {
                this.tabButtonPaneSelected.classList.add('active');
                this.tabButtonSelected.classList.add('active');
            }
            else {
                this.tabPane[0].classList.add('active');
                this.tabItems[0].classList.add('active');
            }
        }
    }

    _addMobileInit() {
        let slides = this.tabItems.length;

        if (slides > 3) {
            this._initSwiper();
        }
    }

    _openTab(el) {
        let _button = el.currentTarget;
        let _tabTarget = _button.dataset.target;

        this._removeActiveTabs();

        document.querySelector(_tabTarget).classList.add('active');

        _button.classList.add('active');

        // console.debug(_button,_tabTarget);
        /*if (this.swiper) {

            let _parent = _button.parentNode;
            let slide = this.swiper.el;
            let activeIndex = this.swiper.activeIndex;

            // this.swiper.slideTo(_parent);
            // this.swiper.update();

            console.debug(_button,_parent, slide,activeIndex);
        }*/
    }

    async _initSwiper() {
        if (!this.swiper) {
            const Swiper = await import('swiper');
            this.swiper = new Swiper.default(this.tabGroup, {
                slidesPerView: '2',
                grabCursor: true,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                watchOverflow: true,
                scrollbar: {
                    el: '.swiper-scrollbar',
                },
            });
        } else {
            this.swiper.update();
        }
    }

    _destroySwiper() {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = undefined;
        }
    }
}

register.registerClass('.widget-tab-slide', TabsSlide);
