import LazyLoad from 'vanilla-lazyload';

const lazyLoad = new LazyLoad({
    elements_selector: '.media-picture--lazy .media-picture__img',
    class_loaded: 'media-picture__img--loaded',
    cancel_on_exit: true,
    callback_loaded: (img) => {
        img.classList.add('media-picture__img--loaded');
        img.closest('picture').classList.add('media-picture--loaded');
        img.dispatchEvent( new CustomEvent('mediaPictureLoaded', { bubbles: true }) );
    },
});

(() => {
    //reload images if adobe target load a dynamic image
    if (window.adobe && window.adobe.target) {
        document.addEventListener(window.adobe.target.event.CONTENT_RENDERING_SUCCEEDED, function () {
            lazyLoad.update();
        });
    }
    lazyLoad.update();
})();

export const forceUpdateLazy = () => {
    lazyLoad.update();
};
