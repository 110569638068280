import Component from '../../libs/component';
import { register } from '../../libs/register';

class DonutGraph extends Component {
    constructor(container) {
        super('widget-donut-graph');

        this.container = container;
        this.graph = container.querySelector('canvas');
        this.baseRadiusSize = 260;
        this.maxFontLength = 100;

        this._initGraph();
    }

    async _initChart() {
        const chartAutoModule = await import('chart.js/auto');
        const chartModule = await import('chart.js');

        const { valueOrDefault, isNullOrUndef, toLineHeight } = await import('chart.js/helpers');
        this.valueOrDefault = valueOrDefault;
        this.isNullOrUndef = isNullOrUndef;
        this.toLineHeight = toLineHeight;

        const Chart = chartAutoModule.default;
        Chart.register(chartModule.ArcElement, chartModule.DoughnutController, chartModule.Legend, chartModule.Title);
        this.Chart = Chart;
    }

    async _initGraph() {
        await this._initChart();

        this.data = this._getGraphData();

        const title = this.data.texts.title;
        const subTitle = this.data.texts.subtitle;
        const centerText = this.data.texts.centerText;

        try {
            this.donutChart = new this.Chart(this.graph, {
                type: 'doughnut',
                data: this.data.values,
                options: {
                    events: [], // Disable mouse interaction
                    responsive: true,
                    maintainAspectRatio: false,
                    //aspectRatio: 1.7,
                    cutout: '70%',
                    rotation: 0,
                    //radius: '80%',
                    onResize: (chart) => {
                        chart.update('resize');
                    },
                    layout: {
                        padding: {
                            top: 10,
                        },
                    },
                    borderWidth: 0,
                    plugins: {
                        title: {
                            display: () => !window.deviceBreakpoints.bpMobile.matches && (title || subTitle),
                            position: 'bottom',
                            padding: {
                                top: 30,
                            },
                            font: {
                                family: 'KarbonMedium',
                                size: 18,
                                lineHeight: 2.5,
                            },
                            color: '#000000',
                            text: [title, subTitle],
                        },
                        legend: {
                            display: false,
                            labels: {
                                generateLabels: (chart) => {
                                    const data = chart.data;
                                    const perc = this._getPercentage;
                                    const altSeparator = this.data.values.altSeparator;
                                    if (data.labels.length && data.datasets.length) {
                                        const {
                                            labels: { pointStyle },
                                        } = chart.legend.options;
                                        return data.labels.map((label, i) => {
                                            const meta = chart.getDatasetMeta(0);
                                            const style = meta.controller.getStyle(i);
                                            return {
                                                text: `${label} ${perc(data.datasets[0].data[i], altSeparator)}`,
                                                fillStyle: style.backgroundColor,
                                                strokeStyle: style.borderColor,
                                                lineWidth: style.borderWidth,
                                                pointStyle: pointStyle,
                                                hidden: !chart.getDataVisibility(i),
                                                index: i,
                                            };
                                        });
                                    }
                                    return [];
                                },
                            },
                        },
                        customDoughnut: {
                            color: '#B2B4B3',
                            labelColor: 'black',
                            formatter: (value) => `${value}`,
                            font: {
                                family: 'KarbonMedium',
                                resizable: true,
                                size: 14,
                                minSize: 12,
                                maxSize: 14,
                                //lineHeight: 1.2
                            },
                            centeredText: {
                                family: 'KarbonMedium',
                                minSize: 14,
                                maxSize: 18,
                                lineHeight: 24,
                                color: '#000000',
                                sidePadding: 15,
                                content: centerText,
                            },
                        },
                        htmlLegend: {
                            containerName: 'widget-donut-graph__legend',
                        },
                    },
                },
                plugins: [this._getCustomDoughnut(), this._getHtmlLegend()],
            });
        } catch (err) {
            console.log('Chart Init failed!', err);
            this.container.classList.remove('init');
        }

        const canvasContainer = this.container.querySelector(this._el('canvas', true));
        canvasContainer.removeAttribute('data-graph-values');
    }

    _getCustomDoughnut() {
        const customDoughnut = {
            id: 'customDoughnut',
            beforeInit: (chart) => {
                //console.log(chart);
                this.container.classList.add('init');

                // Config reset
                chart.options.radius = '100%';
                chart.options.maintainAspectRatio = false;
                chart.options.layout.padding.bottom = 0;

                if (!this.data.texts.title && !this.data.texts.subTitle) {
                    // Adding config when no title or subtitle exists
                    chart.options.layout.padding.bottom = 20;
                }

                switch (true) {
                    case window.deviceBreakpoints.bpMobile.matches:
                        chart.canvas.parentNode.style.height = '100%';
                        chart.options.maintainAspectRatio = true;
                        break;
                    case window.deviceBreakpoints.bpTablet.matches:
                        chart.canvas.parentNode.style.height = '500px';
                        break;
                    /*case window.deviceBreakpoints.bpDesktop.matches:
                        if (!this.data.texts.title && !this.data.texts.subTitle) {  // Adding config when no title or subtitle exists
                            chart.options.layout.padding.bottom = 0;
                            chart.canvas.parentNode.style.height = '630px';
                        } else {
                            chart.canvas.parentNode.style.height = '730px';
                        }
                        break;*/
                }
                console.log('SET HEIGHT INIT');
            },
            beforeDraw: (chart) => {
                const centerConfig = chart.config.options.plugins.customDoughnut.centeredText;
                addCenteredText(chart, centerConfig);
            },
            afterDraw: (chart) => {
                //console.log(chart);

                if (!window.deviceBreakpoints.bpMobile.matches) {
                    const ctx = chart.ctx;
                    const config = chart.config.options.plugins.customDoughnut;
                    const dataset = chart.config.data.datasets[0];
                    const canvasHeight = chart.canvas.style.height.slice(0, -2);
                    const textFontSize = config.font.resizable
                        ? adaptTextSizeToHeight(canvasHeight, config.font.minSize, config.font.maxSize)
                        : config.font.size;

                    ctx.save();
                    ctx.font = `${textFontSize}px ${config.font.family}`;

                    const leftLabelCoordinates = [];
                    const rightLabelCoordinates = [];
                    const chartCenterPoint = {
                        x: (chart.chartArea.right - chart.chartArea.left) / 2 + chart.chartArea.left,
                        y: (chart.chartArea.bottom - chart.chartArea.top) / 2 + chart.chartArea.top,
                    };

                    chart.config.data.labels.forEach((label, i) => {
                        const meta = chart.getDatasetMeta(0);
                        const arcCenterPoint = meta.data[i].getCenterPoint();
                        const arcOuterRadius = meta.data[i].outerRadius;
                        const angle = Math.atan2(
                            arcCenterPoint.y - chartCenterPoint.y,
                            arcCenterPoint.x - chartCenterPoint.x
                        );
                        const lineOffset = -1;
                        const point2X = chartCenterPoint.x + Math.cos(angle) * (arcOuterRadius + lineOffset);
                        let point2Y = chartCenterPoint.y + Math.sin(angle) * (arcOuterRadius + lineOffset);
                        let suitableY;

                        if (point2X < chartCenterPoint.x) {
                            // on the left
                            suitableY = getSuitableY(point2Y, leftLabelCoordinates, lineOffset, 'left');
                        } else {
                            // on the right
                            suitableY = getSuitableY(point2Y, rightLabelCoordinates, lineOffset, 'right');
                        }

                        point2Y = suitableY;

                        let textColor = config.color || 'black';
                        let labelColor = config.labelColor || 'black';
                        let labelText = `${label} ${this._getPercentage(
                            dataset.data[i],
                            this.data.values.altSeparator
                        )}`;

                        if (config && config.formatter) {
                            labelText = config.formatter(labelText).toUpperCase();
                        }

                        let edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10;

                        if (point2X < chartCenterPoint.x) {
                            leftLabelCoordinates.push(point2Y);
                        } else {
                            rightLabelCoordinates.push(point2Y);
                        }

                        //DRAW CODE
                        let textOffsetX = 8;
                        let textSize = getTextSize(ctx, labelText, parseFont(config.font, canvasHeight));
                        let lineEnd =
                            edgePointX < chartCenterPoint.x
                                ? edgePointX + textSize.width + textOffsetX
                                : edgePointX - textSize.width - textOffsetX;

                        this.maxFontLength = textSize.width > this.maxFontLength ? textSize.width : this.maxFontLength;

                        ctx.strokeStyle = textColor;
                        ctx.beginPath();
                        ctx.moveTo(point2X, point2Y);
                        ctx.lineTo(lineEnd, point2Y);
                        ctx.stroke();

                        const labelAlignStyle = edgePointX < chartCenterPoint.x ? 'left' : 'right';
                        const labelX = edgePointX;
                        const labelY = point2Y;

                        ctx.textAlign = labelAlignStyle;
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = labelColor;
                        ctx.fillText(labelText, labelX, labelY);
                    });

                    ctx.restore();

                    adaptGraphSize(chart, this.maxFontLength);
                } else {
                    adaptGraphSize(chart, 0);
                }
            },
        };

        const getSuitableY = (y, yArray = [], offset = 0, direction = 'left') => {
            let result = y;

            yArray.forEach((existedY) => {
                if (existedY - offset < result && existedY + offset > result) {
                    if (direction === 'right') {
                        result = existedY + offset;
                    } else {
                        result = existedY - offset;
                    }
                }
            });

            return result;
        };

        const addCenteredText = (chart, textConfig) => {
            if (textConfig && textConfig.content) {
                // Get ctx from string
                const ctx = chart.ctx;

                // Get options from the center object in options
                const fontFamily = textConfig.family || 'Arial';
                const txt = textConfig.content;
                const color = textConfig.color || '#000';
                const minFontSize = textConfig.minSize || 12;
                const maxFontSize = textConfig.maxSize || 25;
                const sidePadding = textConfig.sidePadding || 20;
                const innerRadius = chart._metasets[chart._metasets.length - 1].data[0].innerRadius;
                const sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2);

                ctx.font = `${maxFontSize}px ${fontFamily}`;

                // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                const stringWidth = ctx.measureText(txt).width;
                const elementWidth = innerRadius * 2 - sidePaddingCalculated;

                // Find out how much the font can grow in width.
                const widthRatio = elementWidth / stringWidth;
                const newFontSize = Math.floor(maxFontSize * widthRatio);
                const elementHeight = innerRadius * 2;

                // Pick a new font size so it will not be larger than the height of label.
                let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                const lineHeight = textConfig.lineHeight || 25;
                let wrapText = false;

                if (fontSizeToUse < minFontSize) {
                    fontSizeToUse = minFontSize;
                    wrapText = true;
                }

                // Set font settings to draw it correctly.
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                let centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                ctx.font = `${fontSizeToUse}px ${fontFamily}`;
                ctx.fillStyle = color;

                if (!wrapText) {
                    ctx.fillText(txt, centerX, centerY);
                    return;
                }

                const words = `${txt}`.split(' ');
                let line = '';
                let lines = [];

                // Break words up into multiple lines if necessary
                for (let [i, el] of words.entries()) {
                    let testLine = line + el + ' ';
                    let metrics = ctx.measureText(testLine);
                    let testWidth = metrics.width;
                    if (testWidth > elementWidth && i > 0) {
                        lines.push(line);
                        line = el + ' ';
                    } else {
                        line = testLine;
                    }
                }

                // Move the center up depending on line height and number of lines
                centerY -= (lines.length / 2) * lineHeight;

                for (let el of lines.entries()) {
                    ctx.fillText(el[1], centerX, centerY);
                    centerY += lineHeight;
                }
                //Draw text in center
                ctx.fillText(line, centerX, centerY);
            }
        };

        const getTextSize = (ctx, lines, font) => {
            const items = [].concat(lines);
            const ilen = items.length;
            const prev = ctx.font;
            let width = 0;

            ctx.font = font.string;

            for (let i = 0; i < ilen; ++i) {
                width = Math.max(ctx.measureText(items[i]).width, width);
            }

            ctx.font = prev;

            return {
                height: ilen * font.lineHeight,
                width: width,
            };
        };

        const parseFont = (value, height) => {
            const global = this.Chart.defaults;
            let size = this.valueOrDefault(value.size, global.font.size);

            if (value.resizable) {
                size = adaptTextSizeToHeight(height, value.minSize, value.maxSize);
            }

            const font = {
                family: this.valueOrDefault(value.family, global.font.family),
                lineHeight: this.toLineHeight(value.lineHeight, size),
                size: size,
                style: this.valueOrDefault(value.style, global.font.style),
                weight: this.valueOrDefault(value.weight, null),
                string: '',
            };

            font.string = toFontString(font);
            return font;
        };

        const toFontString = (font) => {
            if (!font || this.isNullOrUndef(font.size) || this.isNullOrUndef(font.family)) {
                return null;
            }

            return (
                (font.style ? font.style + ' ' : '') +
                (font.weight ? font.weight + ' ' : '') +
                font.size +
                'px ' +
                font.family
            );
        };

        const adaptTextSizeToHeight = (height, min, max) => {
            const size = (height / 100) * 2.5;
            if (min && size < min) {
                return min;
            }
            if (max && size > max) {
                return max;
            }
            return size;
        };

        const adaptGraphSize = (chart, fontLength) => {
            //let radiusSize = chart._metasets[0].data[0].outerRadius;
            const chartWidth = chart.chartArea.width / 2;
            let offsetX = 20;
            let offsetY = window.deviceBreakpoints.bpMobile.matches === true ? 20 : 100;

            if (this.data.texts.title) offsetY += 40;

            if (this.data.texts.subTitle) offsetY += 40;

            const newRadius =
                chartWidth - fontLength - offsetX < this.baseRadiusSize
                    ? chartWidth - fontLength - offsetX
                    : this.baseRadiusSize;
            const newHeight = newRadius * 2 + offsetY;
            const spaceHeight = window.deviceBreakpoints.bpMobile.matches === true ? 30 : 70;

            chart.options.radius = newRadius;
            chart.canvas.parentNode.style.height = newHeight + spaceHeight + 'px';
            chart.resize(chartWidth * 2, newHeight);
            //chart.update('resize');
        };

        return customDoughnut;
    }

    _getHtmlLegend() {
        const htmlLegendPlugin = {
            id: 'htmlLegend',
            afterUpdate(chart, args, options) {
                const ul = getLegendList(chart, options.containerName);

                // Remove old legend items
                while (ul.firstChild) {
                    ul.firstChild.remove();
                }

                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart);

                items.forEach((item) => {
                    const li = document.createElement('li');

                    /*li.onclick = () => {
                        const {type} = chart.config;
                        if (type === 'pie' || type === 'doughnut') {
                            // Pie and doughnut charts only have a single dataset and visibility is per item
                            chart.toggleDataVisibility(item.index);
                        } else {
                            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                        }
                        chart.update();
                    };*/

                    // Color box
                    const boxSpan = document.createElement('span');
                    boxSpan.style.background = item.fillStyle;
                    if (item.strokeStyle) boxSpan.style.borderColor = item.strokeStyle;
                    if (item.lineWidth) boxSpan.style.borderWidth = item.lineWidth + 'px';

                    // Text
                    const textContainer = document.createElement('p');
                    textContainer.style.color = item.fontColor;
                    textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

                    const text = document.createTextNode(item.text);
                    textContainer.appendChild(text);

                    li.appendChild(boxSpan);
                    li.appendChild(textContainer);
                    ul.appendChild(li);
                });
            },
        };

        const getLegendList = (chart, name) => {
            const legendContainer = this.container.querySelector(`.${name}`);
            let listContainer = legendContainer.querySelector('ul');

            if (!listContainer) {
                listContainer = document.createElement('ul');

                legendContainer.appendChild(listContainer);
            }

            return listContainer;
        };

        return htmlLegendPlugin;
    }

    _getGraphData() {
        const canvasContainer = this.container.querySelector(this._el('canvas', true));
        const jsonData = canvasContainer.dataset.graphValues;
        const graphData = jsonData ? JSON.parse(jsonData) : {};

        let labels = [];
        let percentages = [];
        let colors = [];

        for (let val of graphData.values.entries()) {
            labels.push(val[1].label);
            percentages.push(val[1].percentage);
            colors.push(val[1].color);
        }

        return {
            texts: {
                title: graphData.title || '',
                subtitle: graphData.subTitle || '',
                centerText: graphData.centerText || '',
            },
            values: {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: colors,
                        data: percentages,
                    },
                ],
                altSeparator: graphData.altSeparator === 'true',
            },
        };
    }

    _getPercentage(num, altSeparator = false) {
        const perc = num + '%';
        return altSeparator ? perc.replace('.', ',') : perc;
    }
}

register.registerClass('.widget-donut-graph', DonutGraph);
