class Register {
    constructor() {
        this.reg = {};
    }

    /**
     * Register a new class to the register
     * @param {String} selector
     * @param {Class} cl
     */
    registerClass(selector, cl) {
        this.reg[selector] = cl;
    }

    /**
     * Search for all registered class into the root element and apply the logic
     * @param {HTMLELement} rootEl
     */
    apply(rootEl) {
        for (const r in this.reg) {
            if (!this.reg[r]) continue;
            const elements = rootEl.querySelectorAll(r);
            for (const el of elements) {
                try {
                    if (!el.objReference) {
                        el.objReference = new this.reg[r](el);
                        const id = el.id ? el.id : '<id not set>';
                        console.info(`Created object ${r} to component with id ${id}`);
                    }
                } catch (error) {
                    console.error('Cannot apply class to element ' + el, error);
                }
            }
        }
    }

    /**
     * Apply to the specific element the necessary logic
     * @param {HTMLELement} el
     */
    applyTo(el) {
        if (el.objReference) return el.objReference;
        for (const r in this.reg) {
            if (el.matches(r)) {
                el.objReference = new this.reg[r](el);
                return el.objReference;
            }
        }
    }

    /**
     * Get class from element
     * @param {HTMLELement} element
     */
    getClass(element) {
        if (element.objReference) return element.objReference;
        return this.applyTo(element);
    }
}

const defaultRegister = new Register();
export const register = defaultRegister;
