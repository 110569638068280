import Component from '../../libs/component';
import { register } from '../../libs/register';



class Iframe extends Component {

    constructor(container) {
        super('elem-iframe');

        this.container = container;
        this.iframe = this.container.querySelector('iframe');

        switch (true) {
            case window.deviceBreakpoints.bpMobile.matches:
                this._setIframe('mobile');
                break;
            case window.deviceBreakpoints.bpTablet.matches:
                this._setIframe('tablet');
                break;
            case window.deviceBreakpoints.bpDesktop.matches:
                this._setIframe('desktop');
                break;
        }

        window.addEventListener("message",
            (event) => {
                if (!event.data) return;
                if (!event.data.height) return;
                this._setIframeHeight( parseInt(event.data.height) || 1600 );
            },
            false
        );

        document.addEventListener('bpDesktop', () => {
            this._setIframe('desktop');
        });

        document.addEventListener('bpTablet', () => {
            this._setIframe('tablet');
        });

        document.addEventListener('bpMobile', () => {
            this._setIframe('mobile');
        });

        if (this.iframe.dataset.lazySrc)
            this.iframe.setAttribute('src',this.iframe.dataset.lazySrc);  // Init lazy loading of iframe content
    }

    _setIframe(deviceType) {

        const iframeWidth = this.iframe.dataset[deviceType + 'Width'] || this.iframe.dataset['desktopWidth'] || '100%';
        const iframeScrolling = this.iframe.dataset[deviceType + 'Scrolling'] || this.iframe.dataset['desktopScrolling'] || 'no';

        this.iframe.setAttribute('width',iframeWidth);
        this.iframe.setAttribute('scrolling',iframeScrolling);
        this._setIframeHeight(null,deviceType);
    }

    _setIframeHeight(height,deviceType) {

        const iframeHeight = height || this.iframe.dataset[deviceType + 'Height'] || this.iframe.dataset['desktopHeight'] || '2000';

        this.iframe.setAttribute('height',iframeHeight);
    }
}

register.registerClass('.elem-iframe', Iframe);