import Component from '../../libs/component';
import { register } from '../../libs/register'; 

class Slideshow extends Component {
    constructor(container) {
        super('elem-slideshow');

        this.swiperContainer = container.querySelector(`${this._el('container', true)}`);
        this._initSwiper(container);
    }

    async _initSwiper() {
        const Swiper = await import('swiper');
        let _swiperDefaults = {
            modules: [Swiper.Autoplay, Swiper.EffectFade, Swiper.Navigation, Swiper.Pagination, Swiper.Lazy],
            slidesPerView: '1',
            speed: 700,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            preloadImages: true,
            lazy: {
                enabled: false,
                loadPrevNext: true,
                checkInView: true,
                loadOnTransitionStart: true,
                loadedClass: 'media-picture__img--loaded',
            },
            //autoHeight: true,
            roundLengths: true,
            observer: true,
            watchOverflow: true,
            preventInteractionOnTransition: true,
            simulateTouch: false,
            nested: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        };

        if (this.swiper) this._destroySwiper();

        if (this.swiperContainer.classList.contains('autoplay')) {
            _swiperDefaults.autoplay = {
                delay: 7000,
            };

            _swiperDefaults.loop = true;
        }

        this.swiper = new Swiper.default(this.swiperContainer, _swiperDefaults);

        /*this.swiper.slides.forEach((el) => {
            el.addEventListener('mediaPictureLoaded', () => {
                console.log('Picture loaded');
                this.swiper.updateSize(); });
        });*/

        /*this.swiper.on('tap', (swiper) => {
            console.log('TAP',swiper)
        });

        this.swiper.on('click', (swiper) => {
            console.log('CLICK',swiper)
        });*/
    }

    _pauseSlideshow() {
        if (this.swiper)
            if (this.swiper.autoplay.running) {
                this.swiper.autoplay.stop();
                console.info('Carousel slideshow on PAUSE');
            }
    }

    _playSlideshow() {
        if (this.swiper)
            if (!this.swiper.autoplay.running) {
                this.swiper.autoplay.start();
                console.info('Carousel slideshow STARTED');
            }
    }
}

register.registerClass('.elem-slideshow', Slideshow);
