import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
import { setDeviceBreakpoints } from '../../libs/utils';

class Page extends Component {
    constructor(page) {
        super('base-page');

        this.page = page;
        this.breakpoints = {
            bpDesktop: '(min-width: 1024px)',
            bpDesktopNoTablet: '(min-width: 1024px) and (hover: hover)',
            //bpDesktopTablet: '(min-width: 1024px) and (hover: none), (min-width: 1024px) and (hover: hover)',
            //bpTablet: '(min-width: 768px) and (max-width: 1023px)',
            bpTabletLandscape: '(min-width: 1024px) and (orientation: landscape) and (hover: none)',
            //bpMobileTablet: '(max-width: 919px) and (orientation: portrait)',
            bpTablet: '(min-width: 768px) and (max-width: 1023px)',
            bpMobileTablet: '(max-width: 1023px)',
            bpMobile: '(max-width: 767px)',
            orientLandscape: '(orientation: landscape)',
            orientPortrait: '(orientation: portrait)'
        };

        setDeviceBreakpoints(this.breakpoints);

        this._checkFooterHeight();

        this.trackedCtas = [...page.querySelectorAll(`a[data-tracking], button[data-tracking]`)];

        if (this.trackedCtas) {
            this.trackedCtas.forEach((el) => {
                el.addEventListener('click', this._sendAnalytics);
            })
        }
        
        this._initSmootScroll();
    }

    _sendAnalytics({target}) {

        let trackingData = target.dataset.tracking;

        if (trackingData) {
            analytics.sendData(trackingData);
        }
    }

    async _initSmootScroll() {
        const smoothscroll = await import('smoothscroll-polyfill');

        // start scroll polyfill
        smoothscroll.polyfill();
    }

    _toggleBlockPageScroll() {
        const hasBlock = document.documentElement.classList.contains('block-scroll');
        if (hasBlock) this.removeBlockScroll();
        else this.setBlockScroll();
    }

    _setBlockScroll() {
        document.documentElement.classList.add('block-scroll');
    }

    _removeBlockScroll() {
        document.documentElement.classList.remove('block-scroll');
    }

    _checkFooterHeight() {
        const footer = document.querySelector('.base-footer');
        if (footer)
            document.documentElement.style
                .setProperty('--footer-height',`${footer.offsetHeight}px`);
    }
}

register.registerClass('.base-page', Page);

const getPage = () => {
    const page = document.querySelector('.base-page').objReference;
    if (!page) console.error('Cannot find current base-page');
    return page;
};

export const toggleBlockPageScroll = () => {
    getPage()._toggleBlockPageScroll();
};

export const setBlockScroll = () => {
    getPage()._setBlockScroll();
};

export const removeBlockScroll = () => {
    getPage()._removeBlockScroll();
};
