export default class Component {
    constructor(name) {
        this.name = name;
    }

    _elMod(elName, modifier, addDot = false) {
        return `${addDot ? '.' : ''}${this.name}__${elName}--${modifier}`;
    }

    _mod(modifier, addDot = false) {
        return `${addDot ? '.' : ''}${this.name}--${modifier}`;
    }

    _el(elName, addDot = false) {
        return `${addDot ? '.' : ''}${this.name}__${elName}`;
    }
}
