import Component from '../../libs/component';
import { register } from '../../libs/register';

class WrapText extends Component {
    constructor(container) {
        super('layout-wrap-text');
        
        this.container = container;
        this.image = container.querySelector(`${this._el('picture',true)}`);
        this.slideshow = container.querySelector(`${this._el('slideshow',true)}`)
        this.text = container.querySelector(`${this._el('text',true)}`);

        this.position = this.container.dataset.position;

        if (this.position === 'image-center' || this.position === 'image-bottom') {
            //document.addEventListener('orientLandscape', () => this._setPositionMobile(this.position) );
            document.addEventListener('orientPortrait', () => this._setPositionMobile(this.position) );
            document.addEventListener('bpMobileTablet', () => this._setPositionMobile(this.position) );
            document.addEventListener('bpTabletLandscape', () => this._setPositionDesktop(this.position) );
            document.addEventListener('bpDesktopNoTablet', () => this._setPositionDesktop(this.position) );
            
            
            
            if ( window.deviceBreakpoints.bpDesktop.matches === true ? this._setPositionDesktop(this.position) : this._setPositionMobile(this.position));

        }

    }

    _setPositionDesktop() {
        let textHeight = this.text.offsetHeight;

        // Image Position
        if (this.image) {
            let imageHeight = this.image.offsetHeight;


            console.debug('IMAGE DESKTOP', textHeight, imageHeight);

            if (this.position === 'image-bottom') {
                
                this.image.style.marginTop = textHeight - imageHeight + 'px';

            } else if (this.position === 'image-center'){
                
                this.image.style.marginTop = (textHeight - imageHeight) / 2 + 44 + 'px';

            }
        }
            
        // Slideshow Position
        if (this.slideshow) {
            let slideshowHeight = this.slideshow.offsetHeight;

            console.debug('SLIDESHOW DESKTOP', textHeight, slideshowHeight);

            if (this.position === 'image-bottom') {
                
                this.slideshow.style.marginTop = textHeight - slideshowHeight + 'px';

            } else if (this.position === 'image-center'){
                
                this.slideshow.style.marginTop = (textHeight - slideshowHeight) / 2 + 44 + 'px';

            }
        }
            
    }

    _setPositionMobile() {
        let textHeight = this.text.offsetHeight;

        // Image Position
        if (this.image) {
            let imageHeight = this.image.offsetHeight;

            console.debug('IMAGE MOBILE',textHeight, imageHeight);

            if (this.position === 'image-bottom') {
                this.image.style.marginTop = 0;

            } else if (this.position === 'image-center'){
                this.image.style.marginTop = (textHeight - imageHeight) / 2 + 24 + 'px';
            }
        }
            
        // Slideshow Position
        if (this.slideshow) {
            let slideshowHeight = this.slideshow.offsetHeight;

            console.debug('SLIDESHOW MOBILE', textHeight, slideshowHeight);

            if (this.position === 'image-bottom' || this.position === 'image-center') {
                this.slideshow.style.marginTop = 0;
            }
        }
    }

     
}

register.registerClass('.layout-wrap-text', WrapText);