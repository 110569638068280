import Component from '../../libs/component';
import { register } from '../../libs/register';

const tabId = window.location.href.split('tabId=').pop().split('&')[0];

class Tabs extends Component {

    constructor(container) {
        super('widget-tab');

        this.tabContainer = container;
        this.tabGroup = this.tabContainer.querySelector('.widget-tab__tab-group');
        this.tabItems = [...this.tabGroup.children];
        this.tabContent = this.tabContainer.querySelector('.widget-tab__tab-content');
        this.tabPane = [...this.tabContent.children];

        this.tabButtonPaneSelected = document.getElementById(tabId);
        this.tabButtonSelected = document.querySelector('[data-target="#' + tabId + '"]');
 
        this._setTab();

        this.tabItems.forEach((el) => {
            el.addEventListener('click', (ev) => {
                this._openTab(ev);
            });
        });

        window.addEventListener('load', () => this. _scrollToTab() );
        window.addEventListener('visibilitychange', () => this. _scrollToTab() );
    }

    _scrollToTab() {
        if (this.tabButtonSelected) {
            this.tabButtonSelected.scrollIntoView();
        }
    }

    _removeActiveTabs() {
        this.tabPane.forEach((el) => {
            el.classList.remove('active');
        });

        this.tabItems.forEach((el) => {
            el.classList.remove('active');
        });
    }

    _setTab() {
        if (this.tabItems) {
            this._removeActiveTabs();

            if (this.tabButtonSelected) {
                this.tabButtonPaneSelected.classList.add('active');
                this.tabButtonSelected.classList.add('active');
            }
            else {
                this.tabPane[0].classList.add('active');
                this.tabItems[0].classList.add('active');
            }
        }
    }

    _openTab(el){

        let _button = el.currentTarget;
        let _tabTarget = _button.dataset.target;
        
        this._removeActiveTabs();

        document.querySelector(_tabTarget).classList.add("active");
   
        _button.classList.add("active");

        console.debug(_button,_tabTarget);
        
    }
}

register.registerClass('.widget-tab', Tabs);