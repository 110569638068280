import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
import { getNextSibling, isIE } from '../../libs/utils';
import { setBlockScroll, removeBlockScroll } from '../base-page';



class Header extends Component {
    
    constructor(container) {
        super('base-header');

        this.SCROLLED_HIDDEN_MENU_SELECTOR = 'scrolled-hidden';
        this.SCROLLED_MENU_SELECTOR = 'scrolled';
        this.OPENED_MOBILE_MENU_SELECTOR = 'nav--opened';
        this.OPENED_DESKTOP_MENU_SELECTOR = 'flyout--active';
        this.CLOSING_DESKTOP_MENU_SELECTOR = 'flyout--closing';
        this.ACTIVE_NAV_BUTTON_SELECTOR = 'active';
        this.NO_MENU_ANIMATION_SELECTOR = 'no-anim';
        this.ACTIVE_FLYOUT_MENU_SELECTOR = 'flyout--active';
        this.FLYOUT_OPENED_SELECTOR = 'flyout--opened';
        this.FLYOUT_CLOSING_SELECTOR = 'flyout--closing';
        this.FLYOUT_HIGHLIGHT = 'highlight';
        this.FLYOUT_MAX_COLUMN_ITEMS = 99;
        this.MENU_CLOSING_DELAY = 500;
        this.MOBILE_SCROLL_OFFSET =
            parseInt( getComputedStyle(document.documentElement).getPropertyValue('--header-height').replace('px','') ) || 72;

        this.header = container;
        this.navMenu = this.header.querySelector('nav');
        this.navMenuItems = this.navMenu.querySelector(this._el('nav-items',true)).children;
        this.menuOpened = false;
        this.menuTimer = null;

        this._addCommonInit();

        if (isIE())
            this._addDesktopInit();
        else
            window.deviceBreakpoints.bpDesktop.matches === true ? this._addDesktopInit() : this._addMobileInit();
    }

    _addCommonInit() {
        console.log('INIT HEADER COMMONS');

        let mobileRotation = window.matchMedia('(orientation: landscape)');

        mobileRotation.addListener(() => {
            if (this.menuOpened)
                this._closeMobileMenu();
        });

        document.addEventListener('bpDesktop', () => {
            this._cleanMobileMenu();
            this._cleanDesktopMenu();
            this._addDesktopInit();
        });

        document.addEventListener('bpMobileTablet', () => {
            this._cleanDesktopMenu();
            this._cleanMobileMenu();
            this._addMobileInit();
        });

        this.navMenu
            .querySelectorAll('a[href]')
            .forEach((el) => {
                el.addEventListener('click', (ev) => {
                    this._sendAnalytics(ev);
                });
            });

        this._addScrollListener();
    }

    _addMobileInit() {
        console.log('INIT HEADER MOBILE MENU');

        this.navMenu
            .querySelectorAll('li.has-submenu > button')
            .forEach((el) => {

                el.addEventListener('click', (ev) => {
                    let item = ev.target.parentElement;
                    
                    item.parentElement
                        .querySelectorAll('li')
                        .forEach((li) => {
                            li.classList.remove(this.FLYOUT_OPENED_SELECTOR);
                        });
                    
                    item.classList.add(this.FLYOUT_OPENED_SELECTOR);

                    let left = this.navMenu.style.left.replace('%','') | 0;
                    let offset = parseInt(left) - 100;
                    this.navMenu.style.left = `${offset}%`;
                    this.navMenu.classList.add(this.ACTIVE_FLYOUT_MENU_SELECTOR);
                });
            });

        this.navMenu
            .querySelectorAll(`.${this.name}__back-btn`)
            .forEach((el) => {
                el.addEventListener('click', () => {

                    let left = this.navMenu.style.left.replace('%','') | 0;
                    let offset = parseInt(left) + 100;
                    this.navMenu.style.left = `${offset}%`;

                    if (offset == 0)
                        this.navMenu.classList.remove(this.ACTIVE_FLYOUT_MENU_SELECTOR);
                });
            });

        this.header
            .querySelectorAll(`${this._el('menu-btn',true)}, ${this._el('menu-close-btn',true)}`)
            .forEach((el) => {
                el.addEventListener('click', (ev) => {
                    ev.preventDefault();

                    if (this.header.classList.contains(this.OPENED_MOBILE_MENU_SELECTOR)) {
                        this._closeMobileMenu();
                    } else {
                        this._openMobileMenu();
                    }
                });
            });
    }

    _cleanMobileMenu() {
        console.log('CLEANING HEADER MOBILE MENU');

        this._closeMobileMenu();

        this.navMenu
            .querySelectorAll('li.has-submenu > button')
            .forEach((el) => el.replaceWith(el.cloneNode(true)));

        this.navMenu
            .querySelectorAll(`.${this.name}__back-btn`)
            .forEach((el) => el.replaceWith(el.cloneNode(true)));

        this.header
            .querySelectorAll(`${this._el('menu-btn',true)}, ${this._el('menu-close-btn',true)}`)
            .forEach((el) => el.replaceWith(el.cloneNode(true)));
    }

    _addDesktopInit() {
        console.log('INIT HEADER DESKTOP MENU');

        if (this.navMenu) {

            let navMenus = this.navMenu
                .querySelectorAll(this._el('nav-item.has-submenu',true));
            navMenus.forEach((el) => {

                el.addEventListener('mouseover', (ev) => {
                    this._handleNavButton(ev);
                });
                el.addEventListener('click', (ev) => {
                    this._handleNavButton(ev);
                });
                el.addEventListener('mouseout', (ev) => {
                    this._handleNavButton(ev);
                });
            });

            let flyoutButtons = this.navMenu
                .querySelectorAll(this._el('flyout-item.has-submenu',true));
            flyoutButtons.forEach((el) => {

                el.addEventListener('mouseover', (ev) => {
                    this._handleFlyoutButton(ev);
                });
                el.addEventListener('click', (ev) => {
                    this._handleFlyoutButton(ev);
                });
                /*el.addEventListener('mouseout', (ev) => {
                    this._handleFlyoutButton(ev);
                });*/
            });

            let flyoutLinks = this.navMenu
                .querySelectorAll(`ul.second-level > li > ${this._el('flyout-link',true)}`);
            flyoutLinks.forEach((el) => {

                el.addEventListener('mouseover', (ev) => {
                    this._handleFlyoutLink(ev);
                });
                el.addEventListener('mouseout', (ev) => {
                    this._handleFlyoutLink(ev);
                });
            });

            let flyoutMenus = this.navMenu
                .querySelectorAll(`${this._el('flyout',true)}`);
            flyoutMenus.forEach((el) => {
                
                el.addEventListener("animationend", (ev) => {
                    //console.log('*** ANIM ENDED',ev);
                    
                    if (ev.animationName == 'flyoutUp') {
                        ev.target
                            .closest(this._el('nav-item.has-submenu',true))
                            .classList
                            .remove(this.FLYOUT_CLOSING_SELECTOR);
                        this.menuAnimating = false;
                        this.menuOpened = false;
                        this._resetMenu();
                        console.log('--- HEADER MENU CLOSED');
                    }
                });
            });

            this._initFlyoutListAnimation();

            this.header
                .querySelectorAll(`${this._el('menu-close-btn',true)}`)
                .forEach((el) => {
                    el.addEventListener( 'click', (ev) => {
                        ev.preventDefault();
                        this._flyoutMenuClose();
                    });
                });
        }
    }

    _cleanDesktopMenu() {
        console.log('CLEANING HEADER DESKTOP MENU');

        let navMenus = this.navMenu.querySelectorAll(this._el('nav-item.has-submenu',true));
        navMenus.forEach((el) => el.replaceWith(el.cloneNode(true)));

        let flyoutButtons = this.navMenu.querySelectorAll(this._el('flyout-item.has-submenu',true));
        flyoutButtons.forEach((el) => el.replaceWith(el.cloneNode(true)));

        let flyoutLinks = this.navMenu
            .querySelectorAll(`${this._el('flyout--vertical',true)} > ul > li > ${this._el('flyout-link',true)}`);
        flyoutLinks.forEach((el) => el.replaceWith(el.cloneNode(true)));

        let flyoutMenus = this.navMenu.querySelectorAll(`${this._el('flyout',true)}`);
        flyoutMenus.forEach((el) => el.replaceWith(el.cloneNode(true)));

        this.header
            .querySelectorAll(`${this._el('menu-close-btn',true)}`)
            .forEach((el) => el.replaceWith(el.cloneNode(true)));
    }

    _handleNavButton(ev) {

        let { target: el } = ev;

        if (el.classList.contains(`${this._el('nav-button')}`)) {

            switch(ev.type) {
                case 'click':
                case 'mouseover':
                    if (!this.menuOpened) {

                        this.menuOpened = true;
                        this.navMenu
                            .classList
                            .add(this.OPENED_DESKTOP_MENU_SELECTOR);

                        this._addClickOutside();
                        this._addScrollCloseMenu();
                        console.log('*** HEADER MENU OPENED')

                    } else {
                        this._closeFlyout(this.navMenu);
                        this.navMenu
                            .classList
                            .add(this.NO_MENU_ANIMATION_SELECTOR);
                    }
                    
                    this._cleanFlyoutItems();
                    this._cleanNavButtons();
                    this._openFlyout(ev.target.closest(`${this._el('nav-item',true)}`));

                    ev.target
                        .classList
                        .add(this.ACTIVE_NAV_BUTTON_SELECTOR);

                    break;
                case 'mouseout':
                    if (ev.relatedTarget)
                        if (ev.relatedTarget.matches(`${this._el('nav-button',true)}`)) {
                            this.navMenu
                                .classList
                                .add(this.NO_MENU_ANIMATION_SELECTOR);
                        } else {
                            let container = ev.target.closest(`${this._el('nav-item.has-submenu',true)}`);
                            
                            if (!container || !container.contains(ev.relatedTarget)) {
                                //console.log('NAV - MOUSEOUT RESET',el,ev.relatedTarget);
                                this._flyoutMenuClose(true);
                            }
                        }
                    break;
            }
        } else {
            if (ev.relatedTarget)
                if (ev.type == 'mouseout') {
                    let container = ev.target.closest(`${this._el('nav-item.has-submenu',true)}`);
                    if (!container.contains(ev.relatedTarget)) {
                        //console.log('NAV - RESET CLOSE',el,ev.relatedTarget);
                        this._flyoutMenuClose(true);
                    }
                }
        }
    }

    _handleFlyoutButton(ev) {
        ev.stopPropagation();

        let { target: el } = ev;

        if (el.classList.contains(`${this._el('flyout-button')}`)) {

            switch(ev.type) {
                case 'click':
                case 'mouseover':
                    this._closeFlyout(el.closest(`${this._el('flyout',true)}`));
                    this._openFlyout(el.closest(`${this._el('flyout-item',true)}`));
                    this._setFlyoutHeight(getNextSibling(el,`${this._el('flyout',true)}`));
                    this._cleanFlyoutItems();
                    this._hightlightItem(el);
                    break;
                /*case 'mouseout':
                    //console.log(el,ev.relatedTarget)

                    if (ev.relatedTarget) {
                        //console.log(ev.relatedTarget.closest(`${this._el('flyout-item.has-submenu',true)}`));
                        
                        let container = el.closest(`${this._el('flyout-item',true)}`);
                        //console.log('FLYOUT CLOSE?',ev.relatedTarget,container.contains(ev.relatedTarget))

                        if (!container.contains(ev.relatedTarget)) {
                            //console.log('FLYOUT - MOUSEOUT CLOSE',el,ev.relatedTarget);
                            this._closeFlyout(el.closest(`${this._el('flyout-items',true)}`));
                            this._cleanFlyoutItems();
                        }
                    }
                    break;*/
            }
        } /*else {
            if (ev.relatedTarget)
                if (ev.type == 'mouseout') {

                    let containerFlyout = el.closest(`${this._el('flyout-item.has-submenu',true)}`);
                    //console.log('FLYOUT (NOT BTN) CLOSE?',ev.relatedTarget,containerFlyout.contains(ev.relatedTarget))

                    if (containerFlyout)
                        if (!containerFlyout.contains(ev.relatedTarget)) {
                            this._closeFlyout(el.closest(`${this._el('flyout-items',true)}`));
                            this._cleanFlyoutItems();
                        }
                }
        }*/
    }

    _handleFlyoutLink(ev) {

        let { target: el } = ev;

        switch(ev.type) {
            case 'mouseover':
                this._cleanFlyoutItems();
                this._hightlightItem(el);
                this._closeFlyout(el.closest(`${this._el('flyout',true)}`));
                break;
            case 'mouseout':
                this._hightlightItem(el,false);
                break;
        }
    }

    _hightlightItem(item,enable=true) {
        let flyoutItems = item.closest(`${this._el('flyout-items',true)}`);

        if (enable) {
            item.classList.add(this.FLYOUT_HIGHLIGHT);
            flyoutItems.classList.add(this.FLYOUT_HIGHLIGHT);
        } else {
            item.classList.remove(this.FLYOUT_HIGHLIGHT);
            flyoutItems.classList.remove(this.FLYOUT_HIGHLIGHT);
        }
    }

    _cleanFlyoutItems() {

        this.navMenu
            .querySelectorAll(`.${this.FLYOUT_HIGHLIGHT}`)
            .forEach((el) => {
                el.classList
                    .remove(this.FLYOUT_HIGHLIGHT);
            });
    }

    _cleanNavButtons() {

        this.navMenu
            .querySelectorAll(`${this._el('nav-item',true)}`)
            .forEach((el) => {
                el.classList
                    .remove(this.FLYOUT_CLOSING_SELECTOR);
                el.firstElementChild
                    .classList
                    .remove(this.ACTIVE_NAV_BUTTON_SELECTOR);
            });
    }

    _addScrollListener() {

        let ticking = false,
            prevScrollPos = 0,
            lastScrollPos = window.scrollY || window.pageYOffset;

        this._checkScrollPosition(lastScrollPos,prevScrollPos,this.MOBILE_SCROLL_OFFSET);

        document.addEventListener('scroll', () => {
            lastScrollPos = window.pageYOffset;

            if (!ticking) {
                window.requestAnimationFrame( () => {
                    this._checkScrollPosition(lastScrollPos,prevScrollPos,this.MOBILE_SCROLL_OFFSET);
                    prevScrollPos = lastScrollPos;
                    ticking = false;
                });

                ticking = true;
            }
        });
    }

    _addScrollCloseMenu() {
        document.addEventListener('scroll', this._scrollCloseMenu);
    }

    _scrollCloseMenu() {
        let menu = document.querySelector('header').objReference;
        let closeMenu = menu._flyoutMenuClose.bind(menu);
        closeMenu();
        document.removeEventListener('scroll', menu._scrollCloseMenu);
    }

    _checkScrollPosition(lastPos,prevPos,offset=0) {

        if (lastPos > 0) {
            this.header.classList.add(this.SCROLLED_MENU_SELECTOR);
        } else {
            this.header.classList.remove(this.SCROLLED_MENU_SELECTOR);
        }

        if (lastPos > offset) {
            if (lastPos - prevPos > 0) {
                this.header.classList.add(this.SCROLLED_HIDDEN_MENU_SELECTOR);
            } else {
                this.header.classList.remove(this.SCROLLED_HIDDEN_MENU_SELECTOR);
            }
        }
    }

    _flyoutMenuClose(withDelay=false) {

        let navItem = this.navMenu.querySelector(`${this._el('nav-item.flyout--opened',true)}`);

        if (navItem) {

            if (withDelay) {

                this._closeDelay();
                return;
            }

            this.navMenu
                .classList
                .remove(this.NO_MENU_ANIMATION_SELECTOR);

            navItem.querySelector('button').classList.remove('active');
            navItem.classList.remove(this.FLYOUT_OPENED_SELECTOR);
            navItem.classList.add(this.FLYOUT_CLOSING_SELECTOR);
            this.navMenu.classList.add(this.CLOSING_DESKTOP_MENU_SELECTOR);
            this._removeClickOutside();
            this.menuAnimating = true;
        }
    }

    _closeDelay() {

        let activeFlyout = this.navMenu.querySelector(`${this._el('flyout-item.flyout--opened',true)} ${this._el('flyout',true)}`)

        this.clearTimer = () => {
            //console.log('/// clear time?')
            if (this.menuTimer) {
                //console.log('/-/-/- timer cleared')
                clearTimeout(this.menuTimer);
                this.menuTimer = null;
                
                if (activeFlyout)
                    activeFlyout.removeEventListener('mouseover', this.clearTimer);

                this.navMenu.removeEventListener('mouseover', this.clearTimer);
            }
        };

        if (this.menuTimer)
            this.clearTimer();

        //activeFlyout.addEventListener('mouseover', (ev) => console.log('ciao',ev));
        
        if (activeFlyout)
            activeFlyout.removeEventListener('mouseover', this.clearTimer);

        this.navMenu.addEventListener('mouseover', this.clearTimer);

        //console.log('/+/+/+ delayed close requested')

        this.menuTimer = setTimeout( () => {
            this.clearTimer();
            this._flyoutMenuClose();
        }, this.MENU_CLOSING_DELAY);
    }
    
    _addClickOutside() {
        document.body.addEventListener('click', this._clickOutside);
    }

    _removeClickOutside() {
        document.body.removeEventListener('click', this._clickOutside);
    }

    _clickOutside(ev) {

        let header = document.querySelector('header'),
            flyout = header.querySelector('li.has-submenu.flyout--opened');

        if (flyout) {
            if (!flyout.contains(ev.target)) {
                console.log('CLICKED OUTSIDE',ev.target);
                header
                    .objReference
                    ._flyoutMenuClose();
            }
        }
    }

    _initFlyoutListAnimation() {

        let flyoutLists = this.navMenu.querySelectorAll(`${this._el('flyout--horizontal',true)} ul`);

        flyoutLists.forEach(list => {
            let children = list.children;
            [].forEach.call(children, (child, index) => {
                child.style.setProperty('--flyout-anim-index', index);
            });
        });
    }

    _openFlyout(container) {
        if (container) {
            container
                .classList
                .add(this.FLYOUT_OPENED_SELECTOR);
            this._cleanFlyoutHeight();
        }
    }

    _closeFlyout(container) {
        if (container) {
            container
                .querySelectorAll(`.${this.FLYOUT_OPENED_SELECTOR}`)
                .forEach((el) => {
                    el.classList.remove(this.FLYOUT_OPENED_SELECTOR);
                });
        }
    }

    _setFlyoutHeight(container) {
        
        if (container) {

            let flyoutItems = container.closest(`${this._el('flyout-items',true)}`),
                screenHeight = window.innerHeight - this.header.offsetHeight,
                items = container.querySelectorAll('li'),
                itemHeight = 100;

                for (const [i, el] of items.entries()) {
                    if (i >= this.FLYOUT_MAX_COLUMN_ITEMS) break;
                    itemHeight += Math.ceil(el.getBoundingClientRect().height);
                }

                if (itemHeight > screenHeight)
                    itemHeight = screenHeight;

                flyoutItems.style.minHeight = itemHeight + 'px';
        }
    }

    _cleanFlyoutHeight(container) {
        
        let flyoutItems;

        if (container)
            flyoutItems = container.querySelectorAll(`${this._el('flyout-items',true)}`);
        else
            flyoutItems = this.header.querySelectorAll(`${this._el('flyout-items',true)}`);

        flyoutItems.forEach((el) => {
            el.style.minHeight = '';
        });
    }

    _openMobileMenu() {

        this.header.classList.add(this.OPENED_MOBILE_MENU_SELECTOR);
        setBlockScroll();
    }

    _closeMobileMenu() {

        if (this.header.classList.contains(this.OPENED_MOBILE_MENU_SELECTOR)) {
            this._resetMenu();
            removeBlockScroll();
        }
    }

    _resetMenu() {

        //if (!this.menuAnimating) {
            this.navMenu.classList.remove(this.OPENED_DESKTOP_MENU_SELECTOR);
            this.navMenu.classList.remove(this.CLOSING_DESKTOP_MENU_SELECTOR);
            this.menuAnimating = false;
            this.menuOpened = false;
        //}

        this.navMenu.classList.remove(this.NO_MENU_ANIMATION_SELECTOR);
        this.header.classList.remove(this.SCROLLED_HIDDEN_MENU_SELECTOR);
        this.header.classList.remove(this.OPENED_MOBILE_MENU_SELECTOR);
        //this.navMenu.classList.remove(this.ACTIVE_FLYOUT_MENU_SELECTOR);
        this.navMenu.style.left = '';
        this._closeFlyout(this.navMenu);
        this._cleanNavButtons();
        this._cleanFlyoutItems();
    }

    _sendAnalytics({target}) {

        let link = target;
        const currentContainer = target.closest('ul') || this.navMenu;
        const thirdLevel = currentContainer.classList.contains('third-level');
        const secondLevel = thirdLevel ? true : currentContainer.classList.contains('second-level');
        const trackingData = {
            'event': 'menu_click',
            'language': document.documentElement.lang,
            'menu': {
                'clicked': {
                    'firstLevel': {
                        'label': null,
                        'targetUrl': null
                    },
                    'secondLevel': {
                        'label': null,
                        'targetUrl': null
                    },
                    'thirdLevel': {
                        'label': null,
                        'targetUrl': null
                    }
                }
            }
        };

        if (thirdLevel) {
            trackingData.menu.clicked.thirdLevel.label = link.textContent.trim() || null;
            trackingData.menu.clicked.thirdLevel.targetUrl = link.getAttribute('href') || null;
            link = target.closest('.base-header__flyout-item.has-submenu').querySelector('button');
        }
        
        if (secondLevel) {
            trackingData.menu.clicked.secondLevel.label = link.textContent.trim() || null;
            trackingData.menu.clicked.secondLevel.targetUrl = link.getAttribute('href') || null;
            link = link.closest('.base-header__nav-item.has-submenu').querySelector('button');
        }

        trackingData.menu.clicked.firstLevel.label = link.dataset.analyticsLabel || link.textContent.trim() || null;
        trackingData.menu.clicked.firstLevel.targetUrl = link.getAttribute('href') || null;
        
        analytics.sendData(trackingData);
    }
}

register.registerClass('.base-header', Header);