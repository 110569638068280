import Component from '../../libs/component';
import { register } from '../../libs/register';

class CountryMap extends Component {
    
    constructor(container) {
        super('widget-hero--country');

        this.countryMap = container;
        this.mapContainer = container.querySelector('.widget-hero__map');
        this.countryItem;
        
        this._initMap();
    }

    async _initMap() {
        // console.debug('init');
        const svgMap = await import('../../libs/svgMap');
        this.map = new svgMap.default({
            targetElementID: 'countryMap',
            mouseWheelZoomEnabled: false,
            mouseWheelZoomWithKey: false,
            initialZoom: 1.2,
            initialPan: {
                x: 720,
                y: 1200
            },
            data: {
                data: {
                    name: {}
                },
                applyData: 'name',
                values: {
                    AL: { name :'Albania', link:'albania'},
                    AT: { name :'Austria', link:'austria'},
                    BY: { name :'Belarus'},
                    BE: { name :'Belgium', link: 'belgium'},
                    BA: { name :'Bosnia and Herzegovina', link: 'bosnia'},
                    BG: { name :'Bulgaria'},
                    HR: { name :'Croatia', link:'croatia'},
                    CZ: { name :'Czech Republic', link:'czech-republic'},
                    DK: { name :'Denmark', link:'denmark'},
                    EE: { name :'Estonia', link:'estonia'},
                    FI: { name :'Finland', link:'finland'},
                    FR: { name :'France'},
                    DE: { name :'Germany', link:'germany'},
                    GR: { name :'Greece', link:'greece'},
                    IS: { name :'Iceland'},
                    IE: { name :'Ireland'},
                    HU: { name :'Hungary', link:'hungary'},
                    IT: { name :'Italy', link: 'italy'},
                    LV: { name :'Latvia', link: 'latvia'},
                    LI: { name :'Liechtenstein'},
                    LT: { name :'Lithuania', link:'lithuania'},
                    LU: { name :'Luxembourg', link: 'luxembourg'},
                    MK: { name :'Macedonia'},
                    MD: { name :'Moldova'},
                    MC: { name :'Monaco'},
                    ME: { name :'Montenegro'},
                    NL: { name :'Netherlands', link:'netherlands'},
                    NO: { name :'Norway', link:'norway'},
                    PL: { name :'Poland', link:'poland'},
                    PT: { name :'Portugal'},
                    RO: { name :'Romania', link:'romania'},
                    RS: { name :'Serbia', link:'serbia'},
                    SK: { name :'Slovakia', link:'slovakia'},
                    SI: { name :'Slovenia', link:'slovenia'},
                    ES: { name :'Spain'},
                    SE: { name :'Sweden', link:'sweden'},
                    CH: { name :'Switzerland', link:'switzerland'},
                    UA: { name :'Ukraine'},
                    GB: { name :'United Kingdom', link:'great-britain'},
                }
            },
             
        });

        this.countryItem = document.querySelectorAll('.svgMap-country');
        
        this.countryItem.forEach((el) => {
            if(el.dataset.link) {
                el.addEventListener('click', (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    this._scrollToCountry(el);
                });
            }
        });

       
    }

    _scrollToCountry(el) {
        let value = el.dataset.link.replace('#','');
        let element = document.getElementById(value);

        if (element) {
            // Get the size and position of our element in the viewport
            const containerBox = element.getBoundingClientRect();
            const headerHeight = document.querySelector('header').getBoundingClientRect().height;
        
            // The top offset of our element is the top position of the 
            // element in the viewport plus the amount the body is scrolled minus the header height
            const offsetTop = containerBox.top + (window.scrollY || window.pageYOffset) - headerHeight;
            
            let scrollOptions = {
                left: 0,
                top: offsetTop,
                behavior: 'smooth'
            }

            window.scrollTo(scrollOptions);
        }
    }

    _destroyMap() {
        if (this.map) {
            this.map.destroy();
        }
    }
}

register.registerClass('.widget-hero--country', CountryMap);