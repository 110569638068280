import Component from '../../libs/component';
import { register } from '../../libs/register';
import { forceUpdateLazy } from '../../components/media-picture';



class Gallery extends Component {

    constructor(container) {
        super('widget-gallery');

        this.gallery = container;
        this.items = container.getElementsByClassName(this._el('item'));
        this.loadButton = container.querySelector('.elem-cta-load-more');
        
        this._init();
    }

    _init() {

        if (this.loadButton) {
            
            this.loadButton.addEventListener('click', (ev) => {
                ev.preventDefault();
                this._fetchImages();
            });
            this.endpoint = this.loadButton.dataset.url || '';
            delete this.loadButton.dataset.url;
        }
    }

    _fetchImages() {

        let request = new XMLHttpRequest();
        let data;
        let dataObj;
        let resPath = this.endpoint ? this.endpoint : '/assets/files/gallery-test.json';
        let galleryPointer = this.items.length || 0;

        resPath += `?galleryPointer=${galleryPointer}`;

        request.open('GET', resPath, true );
        
        request.onload = () => {

            if (request.status === 200) {
                
                data = request.responseText;
                dataObj = JSON.parse(data);

                if (!dataObj.itemsLeft && this.loadButton) {
                    this.loadButton.style.display = 'none';
                }

                dataObj.images.forEach((image) => {

                    let galleryItem = document.createElement('div');
                        galleryItem.classList.add(this._el('item'));
                    
                    let title = document.createElement('div');
                        title.appendChild(document.createTextNode( image.titleText !== 'null' ? image.titleText : '' ));
                        title.classList.add(this._el('item-description'));

                    let link = document.createElement('a');
                        link.classList.add(this._el('link'));
                        link.href = image.imagePath;
                        link.download = '';

                    let imgContainer = document.createElement('div');
                        imgContainer.classList.add(this._el('item-image'));
                        
                        if (image.bgColor !== 'null') {
                            imgContainer.style.backgroundColor = image.bgColor;
                        }

                    let img = document.createElement('img');

                        if(image.previewPath !== 'null') {
                            img.src = image.previewPath;
                        } else {
                            img.src = image.imagePath;
                        }

                        img.alt = image.altText !== 'null' ? image.altText : 'Gallery image';

                    let overlay = document.createElement('div');
                        overlay.classList.add(this._el('item-overlay'));

                    let icon =  document.createElement('em');
                        icon.classList.add('elem-icon-anim');
                        icon.classList.add('icon-download');
                        icon.classList.add('dark');
                        icon.dataset.nocompletion = true;
                        
                        imgContainer.appendChild(img);
                       
                        link.appendChild(icon);
                        link.appendChild(overlay);
                        link.appendChild(imgContainer);

                        galleryItem.appendChild(link);
                        galleryItem.appendChild(title);

                        this.gallery
                            .querySelector(this._el('item-container',true))
                            .appendChild(galleryItem);
                        
                        register.applyTo(icon);
                        forceUpdateLazy();
                });
            }

            if (request.status === 204 && this.loadButton)
                this.loadButton.style.display = 'none';
        }

        request.onerror = () => {
            console.warn('Gallery "Load more" network ERROR');
        };
        
        request.send();
    }
}

register.registerClass('.widget-gallery', Gallery);
