import Component from '../../libs/component';
import { register } from '../../libs/register';

class Table extends Component {

    constructor(container) {
        super('widget-table');

        this.container = container;
        this.containerTable = container.querySelector(`${this._el('container', true)}`);
        this.tableContainer = container.querySelector(`${this._el('table-container', true)}`);
        this.loader = container.querySelector('.loader-table');
        this.tablePath = container.dataset.tablePath;
        this.tableJson;
        this.loadMore = container.querySelector(`${this._el('showMore', true)}`);
        this.searchBar = container.querySelector(this._el('search', true));
        this.filters = container.querySelector(this._el('selects', true));
        this.moreResults = parseInt(container.dataset.moreResults);
        this.syncScroll = false;
        this.heightOfHeadingFull = 0;
        this.heightOfHeadingReduced = 0;
        this.maxHeightOfHeadingTitle = 0;
        this.paddingOfReducedForFullHeading = 0;
        this.tableType = '';

        this._init();
    }

    _init() {
        if (this.container.classList.contains('widget-table--informative')) {
            this.tableType = 'informative';
            this._fetchResults();
            if (this.loadMore) {
                this.loadMore.addEventListener('click', (ev) => {
                    ev.preventDefault();
                    this._showMoreResults(this.moreResults);
                });
            }

            if (this.searchBar) {
                this.searchBar.addEventListener('keyup', () => {
                    this._loadResultsInformativeTable(this.tableJson, this._getFilters(), this.moreResults);
                });
            }

            if (this.filters) {
                this.filterSelects = this.filters.querySelectorAll('.elem-custom-select-filter-table');

                this.filterSelects.forEach((fl) => {
                    fl.addEventListener('change', () => {
                        this._loadResultsInformativeTable(this.tableJson, this._getFilters(), this.moreResults);
                    });
                });
            }

            this.tableContainer.addEventListener('scroll', () => {
                if (this.tableContainer.scrollLeft > 0) {
                    if (!this.tableContainer.classList.contains('scrolled')) {
                        this.tableContainer.classList.add('scrolled');
                    }
                } else {
                    this.tableContainer.classList.remove('scrolled');
                }
            });

            document.addEventListener('bpDesktop', () => {
                this.tableContainer.style.removeProperty('padding-top');
            });

            document.addEventListener('bpMobileTablet', () => {
                let tooltips = this.tableContainer.querySelectorAll(`${this._el('tooltipText', true)}`);
                if (tooltips.length > 0) {
                    this._adjustPaddingForTooltip(tooltips);
                }
            });
        } else if (this.container.classList.contains('widget-table--comparative')) {
            this.tableType = 'comparative';

            this._fetchResults();

            this.tableContainer.addEventListener('scroll', () => {
                if (this.heightOfHeadingReduced == 0) {
                    this.maxHeightOfHeadingTitle = Math.max(
                        ...Array.from(this.container.querySelectorAll('th h3')).map(item => item.offsetHeight)
                    );
                    let maxHeightOfHeadingCta = this.container.querySelector('th .widget-table__button-heading-container').offsetHeight;
                    this.heightOfHeadingReduced = this.maxHeightOfHeadingTitle + maxHeightOfHeadingCta + 20;
                    this.paddingOfReducedForFullHeading = this.heightOfHeadingFull - this.heightOfHeadingReduced;
                }

                if (this.tableContainer.scrollTop > 0) {
                    if (!this.tableContainer.querySelector('thead').classList.contains('scrolled')) {
                        this.tableContainer.querySelector('thead').style.paddingTop = '48px';
                        this.container.querySelectorAll('th').forEach(element => {
                            element.style.height = this.heightOfHeadingReduced + 'px';
                            if (getComputedStyle(this.container.querySelectorAll('th > h5')[0]).display != 'none') {
                                element.style.paddingTop = (this.paddingOfReducedForFullHeading - 10) + 'px';
                            }
                        });

                        this.tableContainer.querySelector('thead > tr').style.maxHeight = this.heightOfHeadingReduced + this.paddingOfReducedForFullHeading + 'px';

                        this.tableContainer.querySelector('thead').classList.add('scrolled');
                    }
                } else {
                    this._setHeadElementsHeights();
                    this.tableContainer.querySelector('thead').style.removeProperty('padding-top');
                    this.tableContainer.querySelector('thead > tr').style.maxHeight = (this.heightOfHeadingFull + 48) + 'px';
                    this.tableContainer.querySelector('thead').classList.remove('scrolled');
                }
            });
        }

    }

    _fetchResults() {
        let staticResource = '';

        if (this.tableType == 'informative') {
            staticResource = '/assets/files/table-informative-test.json';
        } else if (this.tableType == 'comparative') {
            staticResource = '/assets/files/table-comparative-test-7colspan.json';
        }

        let request = new XMLHttpRequest();
        let resPath = this.tablePath ? document.location.origin + this.tablePath + ".table.json" : staticResource;

        request.open('GET', resPath, true);
        request.timeout = 10000;
        request.responseType = 'json';
        request.onload = () => {

            if (request.status === 200) {
                try {
                    let jsonResponse = (typeof request.response === 'string' || request.response instanceof String) ? JSON.parse(request.response) : request.response;
                    this.tableJson = jsonResponse;
                    if (this.tableType == 'informative') {
                        this._loadResultsInformativeTable(jsonResponse, this._getFilters(), this.moreResults);
                    } else if (this.tableType == 'comparative') {
                        this._loadResultsComparativeTable(jsonResponse);
                    }
                } catch (err) {
                    console.warn('Table ERROR:', err);
                }
            } else {
                // show error message?
                console.log(`Table Error ${request.status}: ${request.statusText}`);
            }
        };

        request.onerror = () => {
            console.warn('Table loading network ERROR');
        };

        request.send();
    }

    _loadResultsInformativeTable(jsonTable, filtersEnabled = [], moreResults = 0) {
        if (!this.containerTable.classList.contains(`${this._el('container--hidden')}`)) {
            this.containerTable.classList.add(`${this._el('container--hidden')}`);
            this.loader.classList.remove('loader-table--hidden');
        }

        if (jsonTable) {
            let resultsToShow = [];
            let headingTable = this.container.querySelector('thead');
            let bodyTable = this.container.querySelector('tbody');

            if (!this.container.querySelector('thead tr')) {
                let rowHeading = document.createElement('tr');

                jsonTable.heading.forEach((headingColumn) => {
                    let headingCell = document.createElement('th');
                    if (headingColumn.tooltip_text && headingColumn.tooltip_text != '') {
                        headingCell.innerHTML += '<div class="widget-table__tooltipContainer">' + headingColumn.title + '<span class="icon-info"></span><div class="widget-table__tooltipText"></div></div>';
                        headingCell.querySelector(this._el('tooltipText', true)).innerHTML = headingColumn.tooltip_text;
                        headingCell.classList.add('widget-table__tooltipCell');
                    } else {
                        headingCell.innerHTML = headingColumn.title;
                    }

                    rowHeading.appendChild(headingCell);
                });

                headingTable.appendChild(rowHeading);
            }

            bodyTable.innerHTML = '';

            if (filtersEnabled.length > 0) {
                resultsToShow = jsonTable.rows.filter((row) => {
                    return row.some(rowCell => {
                        if (rowCell.filter && rowCell.filter.trim() != '') {
                            const filtersOfCell = rowCell.filter.split(';');

                            return filtersEnabled.every(filterSingleEnabled => {
                                return filterSingleEnabled.validated.some(validatedItem => {
                                    return filtersOfCell.includes('filter_true') || filtersOfCell.includes(validatedItem.trim());
                                });
                            });
                        } else {
                            return false;
                        }
                    });
                });
            } else {
                resultsToShow = jsonTable.rows;
            }

            if (resultsToShow.length > 0) {
                let ct = 0;
                const searchBarText = this.searchBar ? this.searchBar.value.trim() : '';

                resultsToShow.forEach((result) => {
                    let row = document.createElement('tr');
                    let cellToJump = 0;
                    let hideRowForSearch = true;
                    let ghostRow = false;

                    if (jsonTable.ghost_rows[jsonTable.rows.indexOf(result)] == "true") {
                        row.classList.add(`${this._el('ghost-row')}`);
                        ghostRow = true;
                    }

                    result.forEach((cell, cellIndex) => {
                        if (cellToJump > 0) {
                            cellToJump--;
                            return;
                        }

                        let cellElement = document.createElement('td');

                        if (ghostRow && (cell.colspan == jsonTable.heading.length)) {
                            row.innerHTML = '<td class="cell-ghost-mobile"></td>';
                            cellElement.classList.add(`${this._el('ghost-row-full-desktop')}`)
                        }

                        if (cell.colspan && parseInt(cell.colspan) > 1) {
                            if (cell.colspan >= result.length - cellIndex) {
                                cell.colspan = result.length - cellIndex;
                            }

                            cellElement.setAttribute('colspan', cell.colspan);
                            cellElement.classList.add("text-center");
                            cellToJump = parseInt(cell.colspan - 1);
                        }

                        if (cell.image_src) {
                            cellElement.innerHTML = `<img class="widget-table__icon-in-table" src="${cell.image_src}" alt="${cell.alt_aria_label}" aria-label="${cell.alt_aria_label}" />`;
                        }

                        if (cell.link) {
                            cellElement.innerHTML += `<a href="${cell.link}" aria-label="${cell.alt_aria_label}">${cell.html_label || cell.label}</a>`;
                        } else {
                            cellElement.innerHTML += cell.html_label || cell.label || '';
                        }

                        if ((cell.html_label && (cell.html_label.includes('icon-line') || cell.html_label.includes('icon-check')))) {
                            cellElement.classList.add('text-center');
                        }

                        if (searchBarText == '' || searchBarText.length <= 1) {
                            hideRowForSearch = false;
                        } else {
                            if (cellElement.innerText.toLowerCase().includes(searchBarText.toLowerCase())) {
                                hideRowForSearch = false;
                            }
                        }

                        row.appendChild(cellElement);
                    });

                    if (ct >= moreResults) {
                        row.classList.add('hide');
                    } else {
                        row.classList.add('show');
                        if (ct == moreResults - 1) {
                            row.classList.add('last-show-element');
                        }

                    }

                    if (hideRowForSearch) {
                        return;
                    }

                    bodyTable.appendChild(row);
                    ct++;
                });

                if (ct > moreResults) {
                    this.loadMore.classList.remove('hide');
                } else {
                    if (!this.loadMore.classList.contains('hide')) {
                        this.loadMore.classList.add('hide');
                    }
                }

                this.container.dataset.countResults = moreResults;

                if (ct == 0) {
                    this._printNoResult(jsonTable.heading.length);
                }
            } else {
                this._printNoResult(jsonTable.heading.length);
            }

            let tooltips = this.tableContainer.querySelectorAll(`${this._el('tooltipText', true)}`);
            if (tooltips.length > 0 && window.deviceBreakpoints.bpMobileTablet.matches === true) {
                this._adjustPaddingForTooltip(tooltips);
            }
        }
        this.containerTable.classList.remove(`${this._el('container--hidden')}`);
        this.loader.classList.add('loader-table--hidden');
    }

    _loadResultsComparativeTable(jsonTable) {
        if (!this.containerTable.classList.contains(`${this._el('container--hidden')}`)) {
            this.containerTable.classList.add(`${this._el('container--hidden')}`);
            this.loader.classList.remove('loader-table--hidden');
        }

        let headingTable = this.container.querySelector('thead');
        let bodyTable = this.container.querySelector('tbody');

        if (!this.container.querySelector('thead tr')) {
            let rowHeading = document.createElement('tr');

            if (jsonTable.heading.length > 4) {
                this.container.classList.add('widget-table__more-items');
                this.syncScroll = true;
                headingTable.classList.add('sync-scroll-container');
                rowHeading.classList.add('sync-scroll');
            }

            jsonTable.heading.forEach((headingColumn, index) => {
                if (index == 0) {
                    rowHeading.appendChild(document.createElement('th'));
                } else {
                    let headingCell = document.createElement('th');
                    let iconElement = document.createElement('img');
                    let titleElement = document.createElement('h3');
                    let descriptionElement = document.createElement('h5');
                    let buttonContainer = document.createElement('div');
                    let buttonElement = document.createElement('a');

                    iconElement.src = headingColumn.icon_resource;
                    iconElement.alt = headingColumn.icon_alt;
                    iconElement.width = 80;
                    iconElement.height = 80;
                    titleElement.innerHTML = headingColumn.title;
                    descriptionElement.innerHTML = headingColumn.description;
                    buttonElement.href = headingColumn.cta_link;
                    buttonElement.innerHTML = headingColumn.cta_label;
                    buttonElement.ariaLabel = headingColumn.cta_label;
                    buttonElement.classList.add('btn-primary');
                    buttonElement.classList.add('widget-table__buyCta');
                    buttonContainer.classList.add('widget-table__button-heading-container');
                    buttonContainer.append(buttonElement);


                    headingCell.append(iconElement, titleElement, descriptionElement, buttonContainer);

                    rowHeading.appendChild(headingCell);
                }
            });

            headingTable.appendChild(rowHeading);
            if (this.syncScroll) {
                let bodyRowControls = document.createElement('tr');
                bodyRowControls.classList.add('widget-table__row-controls');
                bodyRowControls.innerHTML = '<td colspan="' + jsonTable.heading.length + '" style="text-align: right;"><div class="widget-table__arrowContainer"><button class="widget-table__arrow widget-table__arrowDisabled widget-table__arrowBack  icon-arrow_left"></button> <button class="widget-table__arrow widget-table__arrowNext icon-arrow_right"></button></div></td>'
                headingTable.appendChild(bodyRowControls);
            }
        }

        bodyTable.innerHTML = '';

        let collapseTableNeedToBeCreated = true;
        let newRowInnerTable = false;
        let skipToNextRow = false;

        jsonTable.rows.forEach((row) => {
            let rowElement, rowCellElement, iconElement;

            if (collapseTableNeedToBeCreated) {
                rowElement = document.createElement('tr');
                rowCellElement = document.createElement('td');
            } else {
                rowElement = bodyTable.lastElementChild;
                rowCellElement = rowElement.querySelector('td:last-child');
            }

            skipToNextRow = false;

            row.forEach((cell, index) => {
                if (cell.colspan && cell.colspan == jsonTable.heading.length) {
                    collapseTableNeedToBeCreated = true;

                    rowElement = document.createElement('tr');

                    if (jsonTable.ghost_rows[jsonTable.rows.indexOf(row)] == 'true') {
                        rowElement.classList.add('active');
                    }

                    rowCellElement = document.createElement('td');
                    rowCellElement.setAttribute('colspan', parseInt(cell.colspan));

                    let innerTableHtml = `<div class="widget-table__collapse-title"><div class="widget-table__collapse-title-section-description">${cell.html_label ? cell.html_label : cell.label}</div><div class="widget-table__icon icon-caret_down"></div></div><div class="widget-table__inner-table-wrapper"><table class="widget-table__inner-table"><tbody></tbody></table></div>`;

                    rowCellElement.innerHTML = innerTableHtml;

                    if (cell.image_src) {
                        iconElement = document.createElement('img');
                        iconElement.src = cell.image_src;
                        iconElement.alt = cell.alt_aria_label;
                        iconElement.width = 32;
                        rowCellElement.querySelector('.widget-table__collapse-title-section-description').prepend(iconElement);
                    }

                    if (this.syncScroll) {
                        rowCellElement.querySelector('table').classList.add('sync-scroll');
                    }

                    skipToNextRow = true;
                } else {
                    if (!skipToNextRow) {
                        if (index == 0) {
                            newRowInnerTable = true;
                        }
                        let innerTableBody = rowCellElement.querySelector('tbody');
                        let innerTableLatestRow = newRowInnerTable ? document.createElement('tr') : innerTableBody.querySelector('tr:last-child');
                        let innerTableCell = document.createElement('td');

                        if (index == 1) {
                            innerTableCell.innerHTML = '<span class="widget-table__absolute-mobile-category">' + (row[0].html_label ? row[0].html_label : (row[0].label ? row[0].label : "")) + '</span>' + (cell.html_label ? cell.html_label : (cell.label ? cell.label : ""));
                        } else {
                            innerTableCell.innerHTML = cell.html_label ? cell.html_label : (cell.label ? cell.label : "");
                        }

                        if ((cell.html_label && (cell.html_label.includes('icon-line') || cell.html_label.includes('icon-check')))) {
                            innerTableCell.classList.add('text-center');
                        }

                        innerTableLatestRow.appendChild(innerTableCell);

                        if (newRowInnerTable) {
                            innerTableBody.appendChild(innerTableLatestRow);
                            newRowInnerTable = false;
                        }
                    }
                }

                if (collapseTableNeedToBeCreated) {
                    rowElement.appendChild(rowCellElement);
                }
            });

            if (collapseTableNeedToBeCreated) {
                rowElement.classList.add('widget-table__collapse');
                bodyTable.appendChild(rowElement);
                collapseTableNeedToBeCreated = false;
            }
        });
        
        let accordionsDefaultOpenTable = bodyTable.querySelectorAll('tr.widget-table__collapse.active');

        if(accordionsDefaultOpenTable.length > 0) {
            accordionsDefaultOpenTable.forEach(defaultAccordion => {
                this._toggleAccordion(defaultAccordion);
            })
        }

        

        bodyTable.querySelectorAll('.widget-table__collapse-title').forEach((at) => {
            at.addEventListener('click', (ev) => {
                ev.target.closest('tr.widget-table__collapse').classList.toggle('active');
                this._toggleAccordion(ev.target);
            });
        });

        this.heightOfHeadingFull = Math.max(
            ...Array.from(this.container.querySelectorAll('th')).map(item => item.offsetHeight)
        )

        this._setHeadElementsHeights();

        if (this.syncScroll) {
            let syncScrollElements = this.container.querySelectorAll('.sync-scroll');
            let arrowButtons = this.container.querySelectorAll('.widget-table__arrow');

            if (syncScrollElements.length > 0) {
                this.container.querySelector('thead').style = 'width: ' + (bodyTable.querySelector(`${this._el('inner-table', true)}`).offsetWidth + 3) + 'px';
                this.container.querySelector('thead > tr').style = 'width: ' + (bodyTable.querySelector(`${this._el('inner-table', true)}`).offsetWidth + 3) + 'px';

                arrowButtons.forEach(button => button.addEventListener('click', (event) => {
                    let scrollWidthTable = ((syncScrollElements[0].scrollWidth - syncScrollElements[0].clientWidth) / (jsonTable.heading.length - 4)) * 2;

                    if (event.target.classList.contains('widget-table__arrowNext')) {
                        syncScrollElements.forEach(div => div.scrollBy({
                            left: scrollWidthTable,
                            behavior: 'smooth'
                        }));
                    } else {
                        syncScrollElements.forEach(div => div.scrollBy({
                            left: -scrollWidthTable,
                            behavior: 'smooth'
                        }));
                    }
                }));

                syncScrollElements.forEach(div => div.addEventListener('scroll', (e) => {
                    if (window.innerWidth < 1280) {
                        syncScrollElements.forEach(d => {
                            d.scrollRight = div.scrollRight;
                            d.scrollLeft = div.scrollLeft;
                        });
                    } else {
                        if ((syncScrollElements[0].scrollWidth - syncScrollElements[0].clientWidth) == syncScrollElements[0].scrollLeft) {
                            this.container.querySelector('.widget-table__arrowNext').classList.add('widget-table__arrowDisabled');
                        } else {
                            if (e.target.scrollLeft == 0) {
                                this.container.querySelector('.widget-table__arrowBack').classList.add('widget-table__arrowDisabled');
                                this.container.querySelector('.widget-table__arrowNext').classList.remove('widget-table__arrowDisabled');
                            } else {
                                this.container.querySelector('.widget-table__arrowNext').classList.remove('widget-table__arrowDisabled');
                                this.container.querySelector('.widget-table__arrowBack').classList.remove('widget-table__arrowDisabled');
                            }
                        }
                    }
                    syncScrollElements.forEach(element => {
                        if (element.scrollLeft > 0) {
                            element.classList.add('scrolled');
                        } else {
                            element.classList.remove('scrolled');
                        }
                    });
                }));
            }
        }

        window.addEventListener('resize', () => {
            this.container.querySelectorAll('th').forEach(element => {
                element.style.height = null;
            });

            this._setHeadElementsHeights();

            if (this.syncScroll) {
                this.container.querySelector('thead').style = 'width: ' + (bodyTable.querySelector(`${this._el('inner-table', true)}`).offsetWidth + 3) + 'px';
                this.container.querySelector('thead > tr').style = 'width: ' + (bodyTable.querySelector(`${this._el('inner-table', true)}`).offsetWidth + 3) + 'px';
            }
        });

        this.containerTable.classList.remove(`${this._el('container--hidden')}`);
        this.loader.classList.add('loader-table--hidden');
    }

    _showMoreResults(moreResults) {
        if (parseInt(this.container.dataset.countResults) >= moreResults) {
            let elementsHidden = this.container.querySelectorAll('tbody tr.hide');
            let ctElementsShow = 0;

            let lastElement = Math.min(moreResults, elementsHidden.length) - 1;

            this.container.querySelector('.last-show-element').classList.remove('last-show-element');

            for (ctElementsShow; ctElementsShow < moreResults; ctElementsShow++) {
                if (elementsHidden[ctElementsShow]) {
                    elementsHidden[ctElementsShow].classList.remove('hide');
                    elementsHidden[ctElementsShow].classList.add('show');
                    if (ctElementsShow == lastElement) {
                        elementsHidden[ctElementsShow].classList.add('last-show-element');
                    }
                } else {
                    this.loadMore.classList.add('hide');
                    break;
                }
            }

            this.container.dataset.countResults = ctElementsShow;
        }
    }

    _getFilters() {

        let filters = [];

        if (!this.filterSelects)
            this.filterSelects = this.filters.querySelectorAll('.elem-custom-select-filter-table');

        this.filterSelects.forEach((sel) => {
            if (sel.dataset.filters) {
                filters.push({ type: sel.querySelector('.elem-custom-select-filter-table__box').innerText, validated: sel.dataset.filters.split(',') });
            }
        });

        return filters;
    }

    _printNoResult(lengthTable) {
        let noResultsLabel = this.container.dataset.labelNoResults;

        if (noResultsLabel) {
            let bodyTable = this.container.querySelector('tbody');
            bodyTable.innerHTML = '<tr><td class="widget-table__noResultsLabel text-center" colspan="' + lengthTable + '">' + noResultsLabel + '</td></tr>';
        }

        if (!this.loadMore.classList.contains('hide')) {
            this.loadMore.classList.add('hide');
        }
    }

    _setHeadElementsHeights() {
        this.container.querySelectorAll('thead > tr th').forEach(element => {
            element.style.removeProperty('height');
            element.style.removeProperty('padding-top');
        });

        this.container.querySelectorAll('th').forEach(element => {
            element.style.height = (this.heightOfHeadingFull + 48) + 'px';
        });
    }

    _toggleAccordion(accordion) {
        let accordionElement = accordion.closest('tr.widget-table__collapse');
        let accordionElementInnerTable = accordionElement.querySelector('.widget-table__inner-table-wrapper');

        if (accordionElement) {
            if (!accordionElementInnerTable.style.maxHeight) {
                accordionElementInnerTable.style.maxHeight = accordionElement.querySelector('table').offsetHeight + 'px';
            } else {
                accordionElementInnerTable.style.removeProperty('max-height');
            }

        }
    }

    _adjustPaddingForTooltip(tooltipsArray) {
        let maxPaddingTable = Math.max(
            ...Array.from(tooltipsArray).map(item => item.offsetHeight)
        );

        this.tableContainer.style.paddingTop = maxPaddingTable + 'px';
    }
}

register.registerClass('.widget-table', Table);